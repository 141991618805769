@import "src/assets/styles/global";

.home_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
}

.home_img {
    max-width: clamp(1rem, 45vw, 25rem);
}

.home_main_banner{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: 100px 0 100px;
    text-align: center;
    font-size: clamp(0.5rem, 1vw + 0.8rem, 1.2rem);
}

.home-typewriter-text{
    justify-self: center;
    padding-bottom: 10px;
    .Typewriter__wrapper{
        font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-weight: bold;
        font-size: 2rem;
    }
}

.home_main_tagline{
    display: grid;
    justify-items: center;
    h1{
        margin: 0;
        font-family: "CeraPRO-Bold", sans-serif, arial;
        font-weight: bold;
    }
    p{
        margin-bottom: 2rem;
    }
}

.home_main_subheading{
    display: grid;
    justify-items: center;
    text-align: center;
    h1{
        margin: 0;
        font-size: 2.5rem;
        font-family: "CeraPRO-Bold", sans-serif, arial;
        font-weight: bold;
    }
    p{
        margin-bottom: 2rem;
        font-size: 1.5rem;
    }
    .action-button-get-started{
        display: block;
        background: #64ca97;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        color: white;
        line-height: 25px;
        font-size: 1rem;
        font-family: "CeraPRO-Bold", sans-serif, arial;
        font-weight: bold;
    }
}

.deployment_instructions{
    p{
        margin-top: 10px;
        font-size: 1rem;
        font-weight: bold;
    }
    ol{
        padding: 20px 50px 10px 50px;
        border-radius: 0.5em;
        background-color: black;
        color: white;
        margin: 0 50px 0 50px;
        text-align: start;
        font-size: 1.25em;
    }
    code{
        background-color: #fafbfc;
        color: black;
        padding: 5px 10px 5px 10px;
        border-radius: 0.25em;
    }

}

.home_technologies{
    display: grid;
    justify-self: center;
    grid-template-columns: min-content min-content min-content;
    justify-items: center;
    margin-bottom: 25px;
    img{
        width: 8rem;
        height: auto;
    }
}

.home_main_banner_button{
    display: grid;
    justify-items: center;
    p{
        font-weight: bold;
    }
}

.home_banner_image{
    img{
        width: 50%;
        height: auto;
    }

}

.home_features_section{
    display: grid;
    background-color: #fafbfc;
    border-radius: 8px;
    padding-bottom: 50px;
    margin: 100px 50px;
    h1{
        justify-self: center;
        font-size: 2.5rem;
        font-family: "CeraPRO-Bold", sans-serif, arial;
        font-weight: bold;
    }
    .home_features_grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1vw;
        row-gap: 50px;
        margin: 0 50px 0 50px;
        justify-self: center;
        .home_features_item{
            h1{
                font-weight: bold;
                font-size: 24px;
            }
            p{
                font-weight: 100;
                font-size: 1.5rem;
                margin: 0;
            }
            img{
                width: 5rem;
                height: auto;
            }
        }
    }
}

.home_create_website{
    @include custom-button;
    width: min-content;
    align-self: center;
    justify-self: center;
    margin-bottom: 10px;
}


@media (max-width: 700px) {
    .home_features_section{
        .home_features_grid{
            width: 100%;
            padding: 0 1rem 0 1rem;
            grid-template-columns: 1fr;
            .home_features_item{
                display: grid;
                img{
                    width: 4rem;
                    justify-self: center;
                }
                .home_features_item_multi_image_container{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    img{
                        width: 50%;
                    }
                }
            }
        }
    }
}
