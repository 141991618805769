.templates_list_content {
  display: grid;
  justify-items: center;

  .templates_list_title {
    font-weight: bold;
    font-size: 2.5rem;
  }
}

.templates_list {
  list-style: none;
  display: grid;
  grid-template-columns:  repeat(3, minmax(350px, 500px));
  column-gap: 5vw;
  justify-items: end;
  row-gap: min(25px, 5vh);
  padding: 0 0 0 0;

  .template_title {
    justify-self: start;
    font-family: "CeraPRO-Bold", sans-serif, arial;
    font-weight: bold;
  }

  .template_card_item {
    display: grid;
    text-align: center;
    position: relative;
    top: 0;
    transition: top ease 0.5s;

    &:hover {
      top: -10px;

      .template_list_item_button_container {
        display: grid;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      box-shadow: 0 0 10px rgba(0, 0, 0, .1);
      background: none;
      padding: 10px;

      &:hover {
        visibility: visible;
        cursor: pointer;
      }
    }

    .template_list_item_button_container {
      grid-template-columns: 1fr;
      justify-items: center;
      position: absolute;
      top: 65%;
      justify-self: center;
      display: none;
    }

    .template_deploy {
      justify-self: center;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: bold;
      background: #64ca97;
      color: white;
      border-radius: .25em;
      white-space: nowrap;
      padding: 10px 20px 10px 20px;
      box-shadow: 0 6px 10px 0 rgba(17, 17, 17, .06);
      border: none;
      align-self: end;
      margin: 10px 0;
      transition: 0.3s ease-in-out;
      /* "to left" / "to right" - affects initial color */
      background: linear-gradient(to left, #64ca97 50%, #64ca57 50%) right;
      background-size: 200%;

      &:hover {
        cursor: pointer;
        transition: 0.3s ease-in-out;
        background-position: left;
      }
    }

    .template_deploy.disabled {
      transition: none;
      background: #ff9901;

      &:hover {
        cursor: not-allowed;
        transition: none;
      }
    }

    .template_see_more {
      justify-self: center;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: bold;
      background: black;
      color: white;
      border-radius: .25em;
      white-space: nowrap;
      padding: 10px 20px 10px 20px;
      box-shadow: 0 6px 10px 0 rgba(17, 17, 17, .06);
      border: none;
      align-self: end;

      margin: 10px 0;
      transition: 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        background: white;
        color: black;
        transition: 0.3s ease-in-out;
      }
    }
  }

}

.premium {
  -webkit-box-shadow: 0px 0px 16px 5px rgba(255, 184, 81, 0.55);
  box-shadow: 0px 0px 16px 5px rgba(255, 184, 81, 0.55);
}

.template_sub_photo_container {
  display: grid;
  row-gap: 10px;

  h1 {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  p {
    margin: 0;
  }
}


.website_templates_error_message {
  margin-bottom: 10px;
}

.templates_list_content_skeleton {
  display: grid;
  justify-items: center;
}

.template_card_item_skeleton {
  display: grid;
  text-align: center;
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

.templates_list_skeleton {
  list-style: none;
  display: grid;
  grid-template-columns:  repeat(3, minmax(350px, 500px));
  column-gap: 5vw;
  justify-items: end;
  row-gap: min(25px, 5vh);
  padding: 25px 25px 25px 25px;
}


@media (max-width: 1800px) {
  .templates_list {
    display: grid;
    grid-template-columns:  1fr 1fr;
    padding: 10px 25px 25px 25px;
  }

  .templates_list_skeleton {
    display: grid;
    grid-template-columns:  1fr 1fr;
    padding: 10px 25px 25px 25px;
  }
}

@media (max-width: 1100px) {
  .templates_list_skeleton {
    grid-template-columns: 1fr !important;
    justify-content: center;
    li {
      width: min-content;
    }
  }
}

@media (max-width: 600px) {
  .template_skeleton{
    width: 300px !important;
    height: 450px !important;
  }
}

@media (max-width: 900px) {
  .templates_list {
    grid-template-columns: 1fr !important;
    li {
      width: 80vw;
    }
  }
}


