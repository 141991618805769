.faq-page-container{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;
  height: 100vh;
}

.faq-footer{
  align-self: end;
}

.faq-collapsible-section{
  display: block;
  margin: 0 10vw 10vw 10vw;
}

.faq-holder{

}

.faq-item{
  font-size: 1.5rem;
  font-family: "CeraPRO-Bold", sans-serif, arial;
  p{
    font-size: 1rem;
  }
}

.faq-container{
  .faq-title{
    margin-top: 20px;
    text-align: center;
    font-family: "CeraPRO-Bold", sans-serif, arial;
    font-weight: bold;
  }
}

@media (max-width: 800px) {
  .faq-item{
    font-size: 1rem;
    font-family: "CeraPRO-Bold", sans-serif, arial;
    font-weight: bold;
    p{
      font-size: 1rem;
      font-weight: normal;
      white-space: pre-wrap;
    }
  }
  .faq-collapsible-section{
    margin: 0 0 10vw 0;
  }
}
