@import "src/assets/styles/global";

.header_container{
    display: grid;
    grid-template-columns: 15rem 1fr auto;
    min-height: 80px;
    align-items: center;
    justify-items: end;
    font-size: x-large;
    padding: 0 50px 0 50px;
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0 10px 15px rgba(0,0,0,.05);
    -webkit-box-shadow: 0 10px 15px rgba(0,0,0,.05);
    -moz-box-shadow: 0 10px 15px rgba(0,0,0,.05);
    z-index: 1000!important;
}

.header_companyname{
    font-family: "CeraPRO-Bold", sans-serif, arial;
    font-weight: bold;
    margin: 0;
    &:hover{
        cursor: pointer;
    }
}

.logo{
    height: clamp(1rem, 10vw, 3rem);
}


.header_sign_in {
    font-size: large;
    border: none;
    background: none;
    color: black;
    text-decoration: underline;
}

.header_sign_in:hover{
    cursor: pointer;
    color: #e1bee7;
}
.header_login_signup{
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.navlink{
    font-family: "CeraPRO-Bold", sans-serif, arial;
    font-weight: bold;
    font-size: 1rem;
    position: relative;
    color: #000;
    text-decoration: none;
}

.navlink:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.navlink:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.header_vertical_separator{
    border-left: 1px solid #d8dbdd;
    height: 40px;
    justify-self: center;
}

.ant-menu-title-content{
    font-size: 1.2rem;
}

.header_collapsing_menu_items{
    display: grid;
    grid-template-columns: auto auto auto auto;
    a,p{
        padding: 0 10px 0 10px;
    }
    p{
        &:hover{
            cursor: pointer;
            color: #00b3ff;
        }
        margin: 0;
    }
}

.header_hamburger_menu{

}

.header_menu_large{
    font-size: 2rem;
}

.header_logo_and_title_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
}

.header_logo{
    height: 75px;
    width: auto;
    &:hover{
        cursor: pointer;
    }
}

.header_companyname{
    padding: 0;
    margin: 0;
    justify-self: start;
    color: black;
    white-space: nowrap;
    text-align: center;
}

.header_container_small{
    display: none;
}

@media (max-width: 900px) {
    .header_hamburger_menu_container{
        display: grid;
        align-items: end;
        justify-items: end;
        .header_hamburger_menu_icon{
            justify-self: end;
            margin-right: 15px;
            font-size: 50px;
            &:hover{
                cursor: pointer;
            }
        }
    }
    .header_collapsing_menu_items{
        display: none;
    }
    .header_container{
        grid-template-columns: 1fr;
    }
    .header_container_small{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    .header_logo_and_title_container_small{
        display: grid;
        grid-template-columns: min-content min-content;
        justify-items: start;
        align-items: center;
    }
    .header_logo_and_title_container{
        display: none;
    }
    .header_collapsing_menu{
        display: none;
    }
    .header_create_account_container{
        display: none;
    }
}

@media (max-width: 500px) {
    .header_container{
        padding: 0 25px 0 0;
    }
}
